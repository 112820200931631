<template>
  <div>
    <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
      <b-card-header>
        <b-card-title>{{$t('tiktok.susGrowth')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="d-flex flex-wrap">
          <b-col class="col-12 col-md-3 p-0 text-center text-md-left">
            <div class="col-12 mb-2">
              <strong>{{$t('instagram.increaseFollowers')}}</strong>
              <b-icon font-scale="1" icon="question-circle" class="icon-tooltip ml-1" id="growth_followers"></b-icon>
              <b-tooltip
                target="growth_followers"
                triggers="hover"
                variant="primary"
              >
                {{$t('twitter.growth_followers_tooltip')}}
              </b-tooltip>
            </div>

            <div class="mb-4" v-for="(item, index) in seriesGrowth()" :key="index">
              <div class="col-12" v-if="seriesGrowth().length - 1 !== index">
                <strong class="h3">{{percentage()[index]}} %</strong>
              </div>

              <div class="col-12">
                <p>
                  <span v-if="item > 0" class="text-success"><feather-icon size="18" :icon="'ArrowUpIcon'" /></span> 
                  <span v-else class="text-danger"><feather-icon size="18" :icon="'ArrowDownIcon'" /></span> 
                  <span class="h5">{{subscribers_count()[index]}} {{$t('campaigns.followers')}}</span>
                  <span class="d-block">{{$t('twitter.last')}} {{days[index]}} {{$t('twitter.days')}}</span>
                </p>
              </div>
            </div>
          </b-col>

          <b-col class="col-12 col-md-9">
            <apexchart
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
              :key="show"
            ></apexchart>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTooltip,
} from "bootstrap-vue";
import apexchart from "vue-apexcharts";
import utils from "@/libs/utils";

export default {
  components: {
    BRow,
    BTooltip,
    apexchart,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    metrics: {
      required: true,
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    percentage() {
      return utils.getPercentagePerformance(this.seriesGrowth())
    },
    subscribers_count() {
      const formatted_array = []
      utils.getSeries(utils.convertData(this.metrics.subscribers_count.performance),'value').forEach((item) => {
        formatted_array.push(utils.separatebycomma(item))
      })
      return formatted_array
    },
    seriesGrowth() {
      return utils.getSeries(utils.convertData(this.metrics.subscribers_growth_prc.performance),'value')
    },
  },
  data() {
    return {
      days: [30, 90, 180],
      show: false,
      series: [
        {
          name: this.$t('campaigns.followers'),
          data: this.subscribers_count(),
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                    ${this.$t('twitter.increment_subs')}${this.days[dataPointIndex]} ${this.$t('twitter.days')}.
                  </div>
                  <div class="p-1">
                    <strong style="margin-right: .3em">${this.$t('campaigns.followers')}: 
                    </strong> ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]} 
                  </div>
                </div>`
            )
          }
        },
        title: {
          text: "",
          align: "left",
        },
        colors: ["#df87f2"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#6c84e0"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          categories: [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`],
        },
      },
    };
  },
  watch: {
    "$i18n.locale"() {
      this.show = !this.show
      this.series[0].name = this.$t('campaigns.followers')
      this.chartOptions.xaxis.categories = [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`]
    },
  },
};
</script>
