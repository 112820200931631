<template>
<error-loading v-if="error" />
  <b-row v-else class="match-height">
    <b-col :lg="cols_print" sm="12">
      <b-row class="match-height">
        <b-col cols="12">
          <b-card class="info-twitter-center">
            <b-row class="col-12">
              <b-col class="mb-2">
                <h4 class="mb-2">{{$t('campaigns.followers')}}</h4>
                <h3 class="mb-2"><strong>{{subs}}</strong></h3>
              </b-col>

              <b-col class="mb-2">
                <h4 class="mb-2">{{$t('twitter.averageRes')}}
                  <b-icon
                    icon="question-circle"
                    id="average_responses"
                    class="icon-question-profile"
                  ></b-icon>
                  <b-tooltip target="average_responses" triggers="hover" variant="primary">
                    {{$t('twitter.average_responses_tooltip')}}
                  </b-tooltip>
                </h4>
                <p class="mb-1"><strong class="h3">
                  <span v-if="reply_avg.value > 0" class="text-success"><feather-icon size="20" :icon="'ArrowUpIcon'" /></span> 
                  <span v-else class="text-danger"><feather-icon size="20" :icon="'ArrowDownIcon'" /></span> 
                  {{utils.separatebycomma(reply_avg.value)}} %</strong>
                </p>
                <p class="text-muted mb-0" v-if="reply_avg.performance !== 0"> {{$t('campaigns.last')}} {{reply_avg.performance}} {{$t('campaigns.days')}}</p>
              </b-col>
              
              <b-col class="mb-1">
                <h4 class="mb-2">{{$t('twitter.engagementRate')}}
                  <b-icon
                    icon="question-circle"
                    id="engagement"
                    class="icon-question-profile"
                  ></b-icon>
                  <b-tooltip target="engagement" triggers="hover" variant="primary">
                    {{$t('twitter.engagement_rate_tooltip')}}
                  </b-tooltip>
                </h4>
                <h3 class="mb-2">
                  <span v-if="er.value > 0" class="text-success"><feather-icon size="20" :icon="'ArrowUpIcon'" /></span> 
                  <span v-else class="text-danger"><feather-icon size="20" :icon="'ArrowDownIcon'" /></span> 
                  <strong> {{ utils.decimalFixed(er.value, 2) }} %</strong>  
                </h3>
              </b-col>
            </b-row>
          </b-card>

          <b-row>
            <b-col class="col-12">
              <ProfileIncrease :metrics="data.report.metrics"/>
            </b-col>

            <b-col class="col-12">
              <Engagement :audienceData="seriesER"/>
            </b-col>
            
            <b-col class="col-12">
              <AverageEngagement :audienceData="data.report.metrics"/>
            </b-col>

            <b-col class="col-12">
              <Content :audienceData="seriesMedia"
              :metrics="data.report.metrics"
              />
            </b-col>

            <b-col class="col-12">
              <RelevantTweets :media="data.report.features.most_media.data" :user="data.report.basic.username"/>
            </b-col>
          </b-row>


          <!-- <b-col cols="12">
            <average-engagement :audienceData="data.report.metrics"/>
          </b-col> -->
        </b-col>
        <b-col cols="12" v-if="isSuperUser">
          <metrics :metrics="data.report.metrics" />
        </b-col>
      </b-row>
    </b-col>
    <b-col lg="3" sm="12" v-if="showEmbedTwitter">
      <timeline :id="username" sourceType="profile" :options="{ tweetLimit: '4' }" widget-class="card"/>
    </b-col>
  </b-row>
</template>
<script>
import {BRow, BCol, BCard, BTooltip} from 'bootstrap-vue'
import utils from '@/libs/utils'
import {Timeline} from 'vue-tweet-embed'
import ProfileIncrease from '@/views/pages/profile/twitter/charts/profileIncrease.vue'
import Engagement from '@/views/pages/profile/twitter/charts/engagement.vue'
import AverageEngagement from '@/views/pages/profile/twitter/charts/averageEngagement.vue'
import Content from '@/views/pages/profile/twitter/charts/content.vue'
import RelevantTweets from '@/views/pages/profile/twitter/charts/relevantTweets.vue'


export default {
  components: {
    RelevantTweets,
    Content,
    AverageEngagement,
    Engagement,
    ProfileIncrease,
    BTooltip,
    Timeline,
    BRow,
    BCol,
    BCard,
    Metrics: () => import('./Metrics.vue'),
    ErrorLoading: () => import('../ErrorLoading.vue')
  },
  data () {
    return {
      utils,
    }
  },
  props: {
    data: {
      Type: Object,
      required: false
    },
    username: {
      Type: String,
      required: true
    },
    error: {
      Type: Boolean,
      required: true
    },
    showEmbedTwitter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    subs() {
      return utils.getFormat(this.data.report.metrics.subscribers_count.value)
    },
    cols_print () {
      return this.showEmbedTwitter ? '9' : '12'
    },
    reply_avg() {
      return utils.getAvaibleData(this.data.report.metrics.reply_avg.performance)
    },
    er() {
      return utils.getAvaibleData(this.data.report.metrics.er.performance)
    },
    seriesER() {
      return utils.getSeries(utils.convertData(this.data.report.metrics.er.performance),'value')
    },
    seriesMedia() {
      return utils.getSeries(utils.convertData(this.data.report.metrics.media_count.performance),'value')
    },
  },
  methods: {
    isSuperUser() {
      return utils.getUserData().is_superuser
    },
  }
}
</script>
<style>
.icon-question-profile {
  width: 15px;
}
.info-twitter-center{
  text-align: center !important;
}
</style>
